<template>
	<div class="text-labels" v-if ="myrole.toUpperCase() == adminrole.toUpperCase() && isactive && allowadduser">

		<div class="form-group mb-0" v-if="isadmanaged==false"> 
			<vueselect id ="search-objusers" :placeholder="placeholder" :options="availableusers" label="name" @input="userSelect" :clearSearchOnSelect="true" :closeOnSelect="true" :noDrop = "showdrop"  :resetOnOptionsChange="true" :filterable="false" @search="availableusersupdate" @search:blur="availableusersupdate" @search:focus="searchfocus"> 


          	<div class="d-flex noresults" slot="no-options">
          			
		          	<span class =" pl-2 mr-auto my-auto" v-if="!sendaninvite && searchquery.length < 2 ">Start typing to search for users.</span>  
		          	<span class =" pl-2 mr-auto my-auto" v-else-if="!sendaninvite">
		          		<span v-if="!alreadyonlist"> {{ emptytext }}</span> <span v-else> This user has already been added.</span>               
		          	</span>
					<div class ="w-100 h-100 d-flex cursor-pionter" v-if="sendaninvite && allowsendaninvite" @click.prevent="inviteuser()"><span class =" pl-2 mr-auto my-auto" v-if="sendaninvite">User not found, send an invitation to join <i  class="fal fa-envelope"> </i></span></div>
					<div class ="w-100 h-100 d-flex cursor-pionter" v-if="sendaninvite && !allowsendaninvite"><span class =" pl-2 mr-auto my-auto">{{ emptytext }}</span></div>

          	</div>

          <template v-slot:option="option">
          	<div class="d-flex tablerow" v-if="cockpitupgradestatus != '' && (cockpitupgradestatus == 'limited' || cockpitupgradestatus == 'request') && membersmodaltype == 'cockpit' && option.resulttype=='team'">
				<div class ="d-flex text-muted " nowrap="nowrap" style = "min-width: 0px">
					<base-avatar :user = "option" :newclass="'tablepic round mr-2 my-auto'" > </base-avatar>  
					<div class="my-auto mr-2 username search-row">          
						<span class="mr-2 username text-muted" style="display: block;"> {{ username(option)}}  <span class ="mr-1" v-if="option.userexist && option.orgrole == ''">(Flowe user found)</span></span> <span class="text-warning" style="line-height: 5px;"><small> Teams can only be added to Pro projects.</small> </span>
					</div>
				</div>
			</div>
          	<div class="d-flex tablerow" v-else>
				<div class ="d-flex " nowrap="nowrap" style = "min-width: 0px;">
					<base-avatar :user = "option" :newclass="'tablepic round mr-2 my-auto'" > </base-avatar>  
					<div class="my-auto mr-2 username search-row" style="">          
						<span class="mr-2 username N900-c" style="display: block;"> {{ username(option) }} 
							<span class="mt-1 badge badge-light" v-if="option.orgrole && option.orgrole.toUpperCase()=='DISABLE'">No access</span>
							<span class ="mr-1" v-if="option.userexist && option.orgrole == ''">(Flowe user found)</span> 
						</span> 
					</div>
				</div>
			</div>
        
          </template>


          <template #search="{ attributes, events }" v-if="addbutton">

				
	            <input
	              maxlength="125"
	              @blur="handlefocusout"
	              @keyup="onsearchonkeyup"
	              class="vs__search inlineinput mr-2"
	              v-bind="attributes"
	              v-on="events"
	              ref="inputinlinesearch"
	              style="width:110px;"
	              v-model="inputtext"	              
	            /><button class="btn btn-primary btn-reglg" type="button" @click.stop.prevent="addthiscontact()">Add</button>


	       </template>

      </vueselect>
      <label v-if="1==2" for="input1" class="inputlabel text-subtle">{{searchtext}}</label>
  </div>
</div> 
</template>


<style lang = "scss">
	@import '@/scss/abstracts/variable.scss';
	@import'@/scss/abstracts/mixin.scss';

	.username{

		/*color: red;*/
	}

	/*::-webkit-resizer {
  border: 2px solid black;
  background: red;
  box-shadow: 0 0 5px 5px blue;
  outline: 2px solid yellow;
}

resizer {
  border: 2px solid black;
  background: red;
  box-shadow: 0 0 5px 5px blue;
  outline: 2px solid yellow;
}*/

	.btn-reglg{
		height: 45px;
	}

	.cursor-pionter{
		cursor: pointer;
	}

	.vs__no-options{
		height: 26px;
	}

	.vs__actions{
	  display: none;
	}

	.vs__dropdown-menu{
		/*width: 240px;*/
		/*min-height: 67px;*/
		overflow:hidden;
	}

	@include media-max-width($breakpoints-sm, $breakpoints-md){
		.no-options{
			height: 38px;
		}
	}

	@media(max-width: $breakpoints-md){
		.btn-reglg{
			height: 53px;
		}
	}

	.noresults{
		height: 42px;
		padding-top: 8px;
		padding-bottom: 8px;
		color: $N900;
	}

	#search-objusers{
		border-radius: 0.25rem;
		/*height: 40px;*/
		border: none;
	}

	.search-row{
		flex-grow: 1
	}
</style>
<script type="text/javascript"></script>
<script>
	import vueselect from '@/views/tools/vueselect'
	

 	import {bus} from '@/main'
	export default{
		name:'membersearch',
		props: {

			addbutton:{
	        	type:Boolean,
	        	default: false
	      	},

			isadmanaged:{
	        	type:Boolean,
	        	default: true
	      	},
			includeteams:{
				type: Boolean,
				default: false
			},
			userexists:{
				type: Object,
				default: () =>{}
			},
			hasnodrop:{
				type: Boolean,
				default: false
			},
			hasonindicator:{
				type: Boolean,
				default: false
			},
			mode:{
	            type:String,
	            default: ''
	        },
			membersmodaltype:{
	            type:String,
	            default: ''
	        },
			cockpitupgradestatus:{
		        type:String,
		        default: ''
		    },
			searchtext:{
				type: String,
			},
			emptytext:{
		        type:String,
		        default: '0 matching options.'
	      	},
			myrole:{
		        type:String,
		        default: 'Viewer'
	      	},
	      	isactive:{
		        type: Boolean,
		        default: false
		    },
		    allowadduser:{
		    	type:Boolean, 
		    	default:false
		    },		    
		    placeholder:{
		        type: String,
		        default: 'Click to search and add…'
		    },
		    adminrole:{
		        type: String,
		        default: 'Owner'
		    },
		    defaultrole:{
		    	type: String,
		    	default: 'Viewer'

		    },
			cockpit:{
				type: Object,
				default: () => {}
			
	      	},
	        isactive:{
		        type: Boolean,
		        default: false
		    },
		  	username:{
		        type:Function,
		        default: (user2) =>{
		  	       return user2
		        }
		    },
		    availableusers:{
		    	type:Array,
		    	default: () => []
		    },
		    alreadyonlist:{
		    	type:Boolean,
		    	default: false
		    },
		    allowsendaninvite:{
		    	type:Boolean,
		    	default: true
		    },
		    sendaninvite:{
		    	type:Boolean,
		    	default: false
		    },
		    searchquery:{
		    	type:String,
		    	default: ''
		    },
		    limittoboard:{
		    	type:String,
		    	default: ''
		    },	

		},
		data(){
			return{
				inputtext:"",
				searchactive:false
			}
		},
		mounted() {

			let self = this

	  		setTimeout( function(){
				if( self.$refs && self.$refs.inputinlinesearch ){
					//self.$refs.inputinlinesearch.focus()
				}									
			}, 100)
	  	},
		watch:{

			mode( e,ev ){

				alert('')
			}
		},
		computed: {

			ismobile(){

				return this.$store.state.isMobile
			},
			showdrop(){
				var show  = false
				if (this.hasnodrop){
					var show  = true
					if(this.availableusers.length >= 1 || this.sendaninvite || this.alreadyonlist){
						show = false
					}
				} 

				return show
			},
			organization(){
				return this.$store.state.organizations.selectedorganizations
			}

		},
		methods: {



			userSelect(selected, force){

				console.log( "selected" )
				if( selected ){
					selected.orgstatus = "active"
					this.$emit('userSelect',selected, force)	
					this.inputtext = ""			
				}

			
			},
			
			availableusersupdate(search, loading){

				this.searchactive = false
				this.$emit('onsearchactive', false)
				this.$emit('availableusersupdate',search, loading)
			},

			inviteuser(){
				this.$emit('oninviteuser')
			},

			searchfocus(){
			   this.searchactive = true
			   this.$emit('onsearchactive', true)
		       this.$emit('onupdated')
		    },

		    handlefocusout(){

		    	let self = this

		    	setTimeout( function(){
		    		self.$emit('handlefocusout')
		    	}, 100)
		    	

		       
		    },

		    addthiscontact(){

		    	if( this.inputtext != "" ){
		    		this.$emit('addascontact', this.inputtext )
		    		this.inputtext=""
		    	}

		    	
		    },

		    onsearchonkeyup( e ){

		       let self = this

		       	       

		       if ( (e.key === 'Enter' || e.keyCode === 13) && e.target ) {

		       	this.$emit('onsearchonkeyup', e)	

		       	self.inputtext = ""

		       	setTimeout( function(){
					if( self.$refs && self.$refs.inputinlinesearch ){
						self.$refs.inputinlinesearch.focus()
						self.$refs.inputinlinesearch.value = ""
					}									
				}, 100)
		       }




		       

		    },
			
		},
		components: {
			vueselect
		}		
	}
</script>